import * as logo from '../assets/logo.png'

const img = logo.default;

export default function Topbar({ toggleSidebar }) {
    return (
        <div id="topbar" className="md:hidden h-14 w-full bg-teal-800 flex flex-row justify-between">
            <div className="flex flex-row text-white justify-start my-auto ms-4">
                <img src={img} alt="logo"className="logo-image mr-2 py-2"/>
                <span className="font-semibold self-center text-xl tracking-tight cursor-default select-none">Explorer's Guide</span>
            </div>
            <button id="mobile-sidebar-toggle" className="bg-teal-800 p-2 my-auto ml-2 text-gray-100" onClick={toggleSidebar}>
                <span className="bar-top"></span>
                <span className="bar-mid"></span>
                <span className="bar-bot"></span>
            </button>
        </div>   
    )
}