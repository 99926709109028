import { React, useState } from 'react';
import { ButtonTeal, PlusButton } from "../Buttons"

export default function PinForm({ DEBUG, BASE_URL, lat, lng, csrf, getPins, isResponseOk, currentUser, toggleSidebar }) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState("");
    const [error, setError] = useState("");

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const handleTagChange = (event) => {
        setTag(event.target.value);
    }

    const postPin = (event) => {
        event.preventDefault();
        if (DEBUG) {
            console.log({"Posting pin": {name: name, description: description, lat: lat, lng: lng, tags: tags, username: currentUser}});
            console.log({"csrf": csrf});
        }
        if (name === "") {
            setError("Add a name for your pin.");
            return;
        }
        fetch(`${BASE_URL}/pins/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify({name: name, description: description, lat: lat, lng: lng, tags: tags, username: currentUser}),
        })
        .then(isResponseOk)
        .then((data) => {
            if (DEBUG) {
                console.log(data);
            }
            setName("");
            setDescription("");
            setTags([]);
            setError("");
            getPins();
            if (window.innerWidth < 768) {
                toggleSidebar();
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }

    const addTag = () => {
        if (DEBUG) {
            console.log("Adding tag: " + tag);
        }
        if (tag === "" || tags.includes(tag)) {
            setTag("");
            return;
        }
        else {
            setTags(tags => [...tags, tag.replace(/\s+/g, "")]);
            setTag("");
        }
    }

    const removeTag = (tag) => {
        if (DEBUG) {
            console.log("Removing tag: " + tag);
        }
        setTags(tags.filter(item => item !== tag));
    }

    return (
        <div id="pin-form" className="mt-4 md:my-auto px-6">
            <div className="mb-4 text-center block text-lg text-teal-900 font-bold cursor-default select-none">Add a Pin</div>
            <form className="flex flex-col" onSubmit={postPin}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="name">
                        Name
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 focus:outline outline-gray-200 outline-offset-2 outline-2" 
                        id="name" 
                        type="text" 
                        value={name}
                        maxLength="50"
                        onChange={handleNameChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="description">
                        Description
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 focus:outline outline-gray-200 outline-offset-2 outline-2"
                        id="description"
                        type="text"
                        value={description}
                        maxLength="500"
                        onChange={handleDescriptionChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="tag">
                        Add Tag
                    </label>
                    <div className="flex flex-row mb-3">
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 me-3 text-gray-700 focus:outline outline-gray-200 outline-offset-2 outline-2"
                            id="tag"
                            type="text"
                            value={tag}
                            maxLength="30"
                            onKeyDown={(e) => {if (e.key === ' ' || e.key === 'Enter') e.preventDefault(); if (e.key === 'Enter') addTag();}}
                            onChange={handleTagChange}
                        />
                        <PlusButton buttonType="button" onClick={addTag} />
                    </div>
                    {tags && tags.length > 0 && (
                        <div className="mb-4 flex flex-wrap justify-start text-sm text-emerald-100 font-semibold">
                            {tags.map(tag =>
                                <button
                                    id="tag-button"
                                    className="p-1 me-1 mb-1 flex flex-row bg-teal-700 rounded"
                                    key={tag}
                                    type="button"
                                    title="Delete tag"
                                    onClick={() => removeTag(tag)}
                                >
                                    <div className="mx-1">{tag}</div>
                                    <div id="tag-remove-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="coordinates">
                        Coordinates
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 cursor-default outline-none focus:outline-none"
                        id="coordinates"
                        type="disabled"
                        value={`${lat} ${lng}`}
                        readOnly={true}
                    />
                </div>
                {error && <div className="mb-4 text-center font-semibold text-red-500 text-sm">{error}</div>}
                <div className="flex justify-center">
                    <ButtonTeal outerClass="w-24" buttonText="Save" buttonType="submit" />
                </div>
            </form>
        </div>
    );
}