const PushableButton = ({ outerClass, innerClass="", buttonText, buttonType=null, onClick=null }) => {
    const buttonProps = {
        className: outerClass
    };

    if (buttonType) {
        buttonProps.type = buttonType;
    }

    if (onClick) {
        buttonProps.onClick = onClick;
    }

    const innerProps = {
        className: `inner ${innerClass}`
    }

    return (
        <button {...buttonProps}>
            <div {...innerProps}>
                {buttonText}
            </div>
        </button>
    );
}

export function ButtonTeal({ outerClass="", innerClass="", buttonText, buttonType=null, onClick=null}) {
    return (
        <PushableButton 
            outerClass={`btn-push btn-teal ${outerClass}`}
            innerClass={innerClass}
            buttonText={buttonText}
            buttonType={buttonType}
            onClick={onClick}
        />
    )
}

export function ButtonRed({ outerClass="", innerClass="", buttonText, buttonType=null,  onClick=null }) {
    return (
        <PushableButton 
            outerClass={`btn-push btn-red ${outerClass}`}
            innerClass={innerClass}
            buttonText={buttonText}
            buttonType={buttonType}
            onClick={onClick}
        />
    )
}

export function PlusButton({ buttonType, onClick }) {
    const svg = (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5 pointer-events-none">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
    )

    return (
        <PushableButton 
            outerClass="btn-push btn-teal btn-small my-2"
            buttonText={svg}
            buttonType={buttonType}
            onClick={onClick}
        />
    )
}