import { React, useState } from 'react';
import { ButtonTeal } from "../Buttons"

export default function RegisterForm({ DEBUG, BASE_URL, csrf, handleAuthenticationChange, getCSRF, isResponseOk, onLoginClick, toggleSidebar }) {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [error, setError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [password2Error, setPassword2Error] = useState("");

    const register = (event) =>{
        setError("");
        if (DEBUG) {
            console.log("Registering user: " + username);
            console.log(csrf);
        }
        event.preventDefault();
        if (username === "" || email === "" || password === "" || password2 === "") {
            setError("Please fill in all fields.");
            return;
        }
        if (password !== password2) {
            setError("Passwords do not match.");
            return;
        }
        fetch(`${BASE_URL}/register/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify({ username, email, password, password2 }),
        })
        .then(registerResponse)
        .catch((err) => {
            setError(err);
            console.log(err);
        });
    };

    const registerResponse = (response) => {
        if (response.status >= 200 && response.status <= 299) {
            if (DEBUG) {
                console.log("Registration successful");
            }
            return response.json().then((data) => {
                if (DEBUG) {
                    console.log(data);
                }
                fetch(`${BASE_URL}/login/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrf,
                    },
                    credentials: "include",
                        body: JSON.stringify({username: username, password: password}),
                    })
                    .then(isResponseOk)
                    .then((data) => {
                        if (DEBUG) {
                            console.log(data);
                        }
                        handleAuthenticationChange(true, username);
                        clearState();
                        if (window.innerWidth < 768) {
                            toggleSidebar();
                        }
                        getCSRF();
                    })
                });
        } else {
            if (DEBUG) {
                console.log("Registration failed");
            }
            return response.json().then((data) => {
                if (DEBUG) {
                    console.log(data);
                }
                if (data.username) {
                    if (data.username[0] !== "This field may not be blank.") {
                        setUsernameError(data.username[0]);
                    }
                }
                if (data.email) {
                    if (data.email[0] !== "This field may not be blank.") {
                        setEmailError(data.email[0]);
                    }
                    if (data.email[0] === "This field must be unique.") {
                        setEmailError("A user with that email already exists.");
                    }
                }
                if (data.password) {
                    if (data.password[0] !== "This field may not be blank.") {
                        setPasswordError(data.password[0]);
                    }
                }
                if (data.password2) {
                    if (data.password2[0] !== "This field may not be blank.") {
                        setPassword2Error(data.password2[0]);
                    }
                }
            });
        }
    }
    
    const handleUsernameChange = (event) => {
        setUsernameError("");
        setUsername(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmailError("");
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPasswordError("");
        setPassword(event.target.value);
    }

    const handlePassword2Change = (event) => {
        setPassword2Error("");
        setPassword2(event.target.value);
    }

    const handleLoginClick = () => {
        clearState();
        onLoginClick();
    }

    const clearState = () => {
        setUsername("");
        setEmail("");
        setPassword("");
        setPassword2("");
        setUsernameError("");
        setEmailError("");
        setPasswordError("");
        setPassword2Error("");
        setError("");
    }

    return (
        <div id="register-form" className="px-8">
            <div className="mb-4 text-center block text-lg text-teal-900 font-bold cursor-default select-none">Sign Up</div>
            <form className="flex flex-col" onSubmit={register}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="username">
                        Username
                    </label>
                    {usernameError && <div className="mb-1 text-center font-semibold text-red-500 text-sm">{usernameError}</div>}
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline outline-gray-200 outline-offset-2 outline-2" 
                        id="username" 
                        type="text" 
                        value={username}
                        maxLength="18"
                        onChange={handleUsernameChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="email">
                        Email
                    </label>
                    {emailError && <div className="mb-1 text-center font-semibold text-red-500 text-sm">{emailError}</div>}
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline outline-gray-200 outline-offset-2 outline-2" 
                        id="email" 
                        type="text" 
                        value={email}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="password">
                        Password
                    </label>
                    {passwordError && <div className="mb-1 text-center font-semibold text-red-500 text-sm">{passwordError}</div>}
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 focus:outline outline-gray-200 outline-offset-2 outline-2"
                        id="password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="password2">
                        Confirm Password
                    </label>
                    {password2Error && <div className="mb-1 text-center font-semibold text-red-500 text-sm">{password2Error}</div>}
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 focus:outline outline-gray-200 outline-offset-2 outline-2"
                        id="password2"
                        type="password"
                        value={password2}
                        onChange={handlePassword2Change}
                    />
                </div>
                {error && <div className="mb-4 text-center font-semibold text-red-500 text-sm">{error}</div>}
                <ButtonTeal outerClass="mx-auto w-28" buttonText="Sign Up" buttonType="submit" />
            </form>
            <div className="pt-4 text-center text-gray-700 text-sm font-bold cursor-default select-none">Already have an account?<button className="ps-1 underline" onClick={handleLoginClick}>Log In</button></div>
        </div>
    );
}