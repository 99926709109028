import React from 'react';
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";

export default function AuthForms({
    DEBUG,
    BASE_URL,
    csrf,
    handleAuthenticationChange,
    displayLoginForm,
    displayRegisterForm,
    showLoginForm,
    showRegisterForm,
    getPins,
    getCSRF,
    isResponseOk,                                       
    toggleSidebar,
}) {

    return (
        <div className="auth-form mt-4 md:my-auto">
            {showLoginForm && (
                <LoginForm
                    DEBUG={DEBUG}
                    BASE_URL={BASE_URL}
                    csrf={csrf}
                    handleAuthenticationChange={handleAuthenticationChange}
                    getPins={getPins}
                    getCSRF={getCSRF}
                    isResponseOk={isResponseOk}                                        
                    onRegisterClick={displayRegisterForm}
                    toggleSidebar={toggleSidebar}
                />
            )}
            {showRegisterForm && (
                <RegisterForm
                    DEBUG={DEBUG}
                    BASE_URL={BASE_URL}
                    csrf={csrf}
                    handleAuthenticationChange={handleAuthenticationChange}
                    getCSRF={getCSRF}
                    isResponseOk={isResponseOk}
                    onLoginClick={displayLoginForm}
                    toggleSidebar={toggleSidebar}
                />
            )}
        </div>
    )
}