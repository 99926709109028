import * as logo from '../../assets/logo.png'
//import * as title from '../../assets/title.png'

const logoImg = logo.default;
//const titleImg = title.default;

export default function SidebarDesktopLogo() {
    return (
        <div className="hidden md:block bg-teal-800 p-6">
            <div className="flex flex-row text-white justify-center items-center">
                <img src={logoImg} alt="logo" className="logo-image mr-4 py-1"/>
                {/* <div className=""><img src={titleImg} alt="title" className="title-image"/></div> */}
                <span className="font-semibold text-2xl tracking-tight cursor-default select-none">Explorer's Guide</span>
            </div>
        </div>
    )
}