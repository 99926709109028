import { React, useState } from 'react';
import { ButtonTeal } from "../Buttons"

export default function LoginForm({ DEBUG, BASE_URL, csrf, handleAuthenticationChange, getCSRF, isResponseOk, getPins, onRegisterClick, toggleSidebar }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("")
    
    const login = (event) => {
        event.preventDefault();
        if (username === "" || password === "") {
            setError("Username and password required.");
            return;
        }
        fetch(`${BASE_URL}/login/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf,
            },
            credentials: "include",
            body: JSON.stringify({username: username, password: password}),
        })
        .then(isResponseOk)
        .then((data) => {
            if (DEBUG) {
                console.log(data);
            }
            handleAuthenticationChange(true, username);
            setUsername("");
            setPassword("");
            setError("");
            if (window.innerWidth < 768) {
                toggleSidebar();
            }
            getPins(data.currentUser);
            getCSRF();
        })
        .catch((err) => {
            console.log(err);
            setError("Incorrect login details - try again.");
        });
    }

    const handleUsernameChange = (event) => {
        setUsernameError("");
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPasswordError("");
        setPassword(event.target.value);
    }

    const handleRegisterClick = () => {
        setUsername("");
        setPassword("");
        setError("");
        onRegisterClick();
    }

    return (
        <div id="login-form" className="px-8">       
            <div className="mb-4 text-center block text-lg text-teal-900 font-bold cursor-default select-none">Log In</div>
            <form className="flex flex-col" onSubmit={login}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="username">
                        Username
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline outline-gray-200 outline-offset-2 outline-2" 
                        id="username" 
                        type="text" 
                        value={username}
                        maxLength="18"
                        onChange={handleUsernameChange}
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm font-bold mb-2 cursor-default select-none" htmlFor="password">
                        Password
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 focus:outline outline-gray-200 outline-offset-2 outline-2"
                        id="password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                {error && <div className="mb-4 text-center font-semibold text-red-500 text-sm">{error}</div>}
                <ButtonTeal outerClass="mx-auto w-28" buttonText="Log In" buttonType="submit" />
            </form>
            <div className="pt-4 text-center text-gray-700 text-sm font-bold cursor-default select-none">Need an account?<button className="ps-1 underline" onClick={handleRegisterClick}>Sign Up</button></div>
        </div>
    );
}